import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/lib/locale/ru';
import TTLightTheme from '@uikit/themes/tt.light.theme';
import TTDarkTheme from '@uikit/themes/tt.dark.theme';
import icons from '@uikit/icons';

Vue.use(Vuetify);

const options = {
  theme: {
    default: 'light',
    themes: {
      light: TTLightTheme,
      dark: TTDarkTheme,
    },
    options: {
      // Note: IE does not support CSS Custom Properties
      customProperties: false,
    },
  },

  icons: {
    iconfont: icons.iconfont,
    values: {
      ...icons.values,

      // fa
      angleLeft: 'fa fa-angle-left',
      angleRight: 'fa fa-angle-right',
      caretDown: 'fa fa-caret-down',
      caretUp: 'fa fa-caret-up',
      checkCircle: 'fa fa-check-circle',
      circle: 'fa-circle',
      exclamationCircle: 'fa fa-exclamation-circle',
      pool: 'fa fa-poll',
      square: 'fa-square',
      star: 'fa fa-star',
      stream: 'fa fa-stream',

      // far
      angleDownRegular: 'far fa-angle-down',
      angleUpRegular: 'far fa-angle-up',
      bookOpenReader: 'far fa-book-open-reader',
      chartColumn: 'far fa-chart-column',
      chartNetworkRegular: 'fa-regular fa-chart-network',
      exclamationTriangleRegular: 'far fa-exclamation-triangle',
      plusRegular: 'far fa-plus',
      bolt: 'far fa-bolt',

      // fas
      arrowCircleDown: 'fas fa-arrow-circle-down',
      arrowCircleUp: 'fas fa-arrow-circle-up',
      awardSolid: 'fas fa-award',
      chartLine: 'fas fa-chart-line',
      commentSolid: 'fas fa-comment',
      userSolid: 'fas fa-user',

      // fal
      angleDownLight: 'fal fa-angle-down',
      angleLeftLight: 'fal fa-angle-left',
      angleRightLight: 'fal fa-angle-right',
      angleUpLight: 'fal fa-angle-up',
      angryLight: 'fal fa-angry',
      arrowSquareUpLight: 'fal fa-arrow-square-up',
      arrowToBottomLight: 'fal fa-arrow-to-bottom',
      arrowToTopLight: 'fal fa-arrow-to-top',
      arrowRightLight: 'fal fa-arrow-right',
      awardLight: 'fal fa-award',
      balanceScaleRightLight: 'fal fa-balance-scale-right',
      banLight: 'fal fa-ban',
      bookLight: 'fal fa-book',
      borderAllLight: 'fal fa-border-all',
      boxLight: 'fal fa-box',
      bullhornLight: 'fal fa-bullhorn',
      bullseyeArrowLight: 'fal fa-bullseye-arrow',
      bullseyeLight: 'fal fa-bullseye',
      bullseyePointerLight: 'fal fa-bullseye-pointer',
      calendarAltLight: 'fal fa-calendar-alt',
      calendarCheckLight: 'fal fa-calendar-check',
      calendarEditLight: 'fal fa-calendar-edit',
      calendarLight: 'fal fa-calendar',
      cameraAltLight: 'fal fa-camera-alt',
      cameraLight: 'fal fa-camera',
      caretCircleDownLight: 'fal fa-caret-circle-down',
      chartBarLight: 'fal fa-chart-bar',
      chartLineLight: 'fal fa-chart-line',
      chartPieAltLight: 'fal fa-chart-pie-alt',
      chevronCircleRightLight: 'fal fa-chevron-circle-right',
      chevronLeftLight: 'fal fa-chevron-left',
      chevronRightLight: 'fal fa-chevron-right',
      circleLight: 'fal fa-circle',
      clipboardCheck: 'fal fa-clipboard-check',
      clipboardListCheckLight: 'fal fa-clipboard-list-check',
      clipboardUserLight: 'fal fa-clipboard-user',
      codeLight: 'fal fa-code',
      cog: 'fal fa-cog',
      columnsLight: 'fal fa-columns',
      commentAltCheckLight: 'fal fa-comment-alt-check',
      commentAltLinesLight: 'fal fa-comment-alt-lines',
      commentAltPlusLight: 'fal fa-comment-alt-plus',
      commentLight: 'fal fa-comment',
      commentsAltLight: 'fal fa-comments-alt',
      commentsDotsLight: 'fal fa-comment-dots',
      copy: 'fal fa-copy',
      ellipsisHLight: 'fal fa-ellipsis-h',
      envelopeLight: 'fal fa-envelope',
      envelopeOpenLight: 'fal fa-envelope-open',
      exclamationCircleLight: 'fal fa-exclamation-circle',
      exchangeLight: 'fal fa-exchange',
      expandLight: 'fal fa-expand',
      eye: 'fal fa-eye',
      eyeLight: 'fal fa-eye',
      eyeSlashLight: 'fal fa-eye-slash',
      fileAltLight: 'fal fa-file-alt',
      fileCertificateLight: 'fal fa-file-certificate',
      fileChartPieLight: 'fal fa-file-chart-pie',
      fileCheckLight: 'fal fa-file-check',
      fileEditLight: 'fal fa-file-edit',
      fileImageLight: 'fal fa-file-image',
      fileLight: 'fal fa-file',
      filePdfLight: 'fal fa-file-pdf',
      filePlusLight: 'fal fa-file-plus',
      filePowerpointLight: 'fal fa-file-powerpoint',
      fileSpreadsheetLight: 'fal fa-file-spreadsheet',
      fileTimesLight: 'fal fa-file-times',
      fileVideoLight: 'fal fa-file-video',
      fileWordLight: 'fal fa-file-word',
      filterLight: 'fal fa-filter',
      fireLight: 'fal fa-fire',
      flagLight: 'fal fa-flag',
      folderOpenLight: 'fal fa-folder-open',
      folderTreeLight: 'fal fa-folder-tree',
      frownLight: 'fal fa-frown',
      gearLight: 'fal fa-gear',
      glassesLight: 'fal fa-glasses',
      graduationCapLight: 'fal fa-graduation-cap',
      grinBeamLight: 'fal fa-grin-beam',
      grinHeartsLight: 'fal fa-grin-hearts',
      grinStartLight: 'fal fa-grin-stars',
      gripLinesLight: 'fal fa-grip-lines',
      handPointDownLight: 'fal fa-hand-point-down',
      handPointerLight: 'fal fa-hand-pointer',
      handshakeLight: 'fal fa-handshake',
      imageLight: 'fal fa-image',
      keyboardLight: 'fal fa-keyboard',
      laughLight: 'fal fa-laugh',
      layerGroupLight: 'fal fa-layer-group',
      layerPlusLight: 'fal fa-layer-plus',
      linkLight: 'fal fa-link',
      listAltLight: 'fal fa-list-alt',
      listUlLight: 'fal fa-list-ul',
      lockAltLight: 'fal fa-lock-alt',
      lockOpenAltLight: 'fal fa-lock-open-alt',
      mapMarkerAltLight: 'fal fa-map-marker-alt',
      megaphoneLight: 'fal fa-megaphone',
      mehLight: 'fal fa-meh',
      minusLight: 'fal fa-minus',
      penLight: 'fal fa-pen',
      penToSquareLight: 'fal fa-pen-to-square',
      phoneLight: 'fal fa-phone',
      playCircleLight: 'fal fa-play-circle',
      plusLight: 'fal fa-plus',
      plusSquareLight: 'fal fa-plus-square',
      pollPeopleLight: 'fal fa-poll-people',
      presentationLight: 'fal fa-presentation',
      printLight: 'fal fa-print',
      puzzleLight: 'fal fa-puzzle-piece',
      qrcodeLight: 'fal fa-qrcode',
      questionCircleLight: 'fal fa-question-circle',
      questionLight: 'fal fa-question',
      save: 'fal fa-save',
      shareAltLight: 'fal fa-share-alt',
      slidersLight: 'fal fa-sliders-h',
      smileLight: 'fal fa-smile',
      sortAltLight: 'fal fa-sort-alt',
      squareKanbanLight: 'fal fa-square-kanban',
      starLight: 'fal fa-star',
      stopCircleLight: 'fal fa-stop-circle',
      stopWatchLight: 'fal fa-stopwatch',
      swatchBookLight: 'fal fa-swatchbook',
      syncLight: 'fal fa-sync',
      tachometerLight: 'fal fa-tachometer',
      tasksLight: 'fal fa-tasks',
      timesLight: 'fal fa-times',
      trash: 'fal fa-trash-alt',
      userCheckLight: 'fal fa-user-check',
      userChartLight: 'fal fa-user-chart',
      userEditLight: 'fal fa-user-edit',
      userHardHatLight: 'fal fa-user-hard-hat',
      userLight: 'fal fa-user',
      userPlusLight: 'fal fa-user-plus',
      usersClassLight: 'fal fa-users-class',
      usersCrownLight: 'fal fa-users-crown',
      usersLight: 'fal fa-users',
      videoLight: 'fal fa-video',
    },
  },

  lang: {
    locales: { ru },
    current: 'ru',
  },
};
export default new Vuetify(options);
