/* eslint-disable max-len,vue/max-len */
import * as Names from '@/plugins/vue-router/routeNames';

import createAbilityMiddleware from '@/plugins/vue-router/guards/createAbilityMiddleware';
import createRedirectionByAbilityMiddleware from '@/plugins/vue-router/guards/createRedirectionByAbilityMiddleware';
import { ABILITIES, REPORT_TYPE } from '@/helpers/constants';

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        meta: {
          middleware: [
            createRedirectionByAbilityMiddleware({
              [ABILITIES.CAN_SEE_SURVEYS_LEFT_MENU]: { name: Names.R_APP_SURVEYS },
              default: { name: Names.R_APP_USER_SURVEYS },
            }),
          ],
        },
      },
      {
        path: 'errors/401',
        name: Names.R_ERROR_401,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error401.vue'),
      },
      {
        path: 'errors/403',
        name: Names.R_ERROR_403,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error403.vue'),
      },
      {
        path: 'errors/404',
        name: Names.R_ERROR_404,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error404.vue'),
      },
      {
        path: 'errors/500',
        name: Names.R_ERROR_500,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/errors/Error500.vue'),
      },
    ],
  },
  {
    path: '/demo/:companyId/:surveyId',
    name: Names.R_APP_DEMO_USER_REGISTRATION,
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/DemoUserRegistration.vue'),
  },
  {
    path: '/app',
    name: Names.R_APP,
    redirect: { name: Names.R_APP_SURVEYS },
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/PlatformLayout.vue'),
    children: [
      {
        path: 'surveys',
        name: Names.R_APP_SURVEYS,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Surveys.vue'),
        },
      },
      {
        path: 'hr_practices',
        name: Names.R_APP_PRACTICES,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Practices.vue'),
        },
        meta: { middleware: [createAbilityMiddleware([ABILITIES.CAN_SEE_HR_PRACTICES_LEFT_MENU])] },
      },
      {
        path: 'practices/create',
        name: Names.R_APP_PRACTICES_CREATE,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/components/practices/PracticeCreatePage.vue'),
        },
        meta: { middleware: [createAbilityMiddleware([ABILITIES.CAN_CREATE_PRACTICE])] },
      },
      {
        path: 'practices/edit/:practiceId',
        name: Names.R_APP_PRACTICES_EDIT_ID,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/components/practices/PracticeEditPage.vue'),
        },
        meta: { middleware: [createAbilityMiddleware([ABILITIES.CAN_CREATE_PRACTICE])] },
      },
      {
        path: 'practice/:practiceId',
        name: Names.R_APP_PRACTICE_ID,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/components/practices/PracticePage.vue'),
        },
      },
      {
        path: 'construction',
        name: Names.R_APP_CONSTRUCTION,
        redirect: { name: Names.R_APP_CONSTRUCTION_COMMON },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Constructor.vue'),
        },
        children: [
          {
            path: 'common',
            name: Names.R_APP_CONSTRUCTION_COMMON,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/construction/Common.vue'),
            meta: { middleware: [createAbilityMiddleware([ABILITIES.CAN_SEE_CONSTRUCTOR_LEFT_MENU])] },
          },
          {
            path: 'results/survey/:id',
            name: Names.R_APP_CONSTRUCTION_RESULTS_SURVEY_ID,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/construction/Results.vue'),
          },
          {
            path: 'drafts',
            name: Names.R_APP_CONSTRUCTION_DRAFTS,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/construction/Drafts.vue'),
          },
          {
            path: 'trash',
            name: Names.R_APP_CONSTRUCTION_TRASH,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/construction/Trash.vue'),
          },
          {
            path: 'templates',
            name: Names.R_APP_CONSTRUCTION_TEMPLATES,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/construction/UserTemplates.vue'),
          },
          {
            path: 'shop',
            name: Names.R_APP_CONSTRUCTION_SHOP,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/construction/Shop.vue'),
          },
          {
            path: 'branching/survey/:id',
            name: Names.R_APP_CONSTRUCTION_BRANCHING_SURVEY_ID,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/construction/Branching.vue'),
          },
        ],
      },
      {
        path: 'survey',
        name: Names.R_APP_SURVEY,
        redirect: { name: Names.R_ERROR_404 },
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Survey.vue'),
        },
        children: [
          {
            path: ':id/statistics',
            name: Names.R_APP_SURVEY_ID_STATISTICS,
            component: () => import(/* webpackChunkName "[request]" */ '@/views/app/Statistics.vue'),
          },
          {
            path: ':id/flexible/:tab?',
            name: Names.R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS,
            component: () => import(/* webpackChunkName "[request]" */ '@/views/app/FlexibleAnalytics.vue'),
          },
          {
            path: ':id/action-plan-trash',
            name: Names.R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS_ACTION_PLAN_TRASH,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/flexibleAnalytics/ActionsPlanTrash.vue'),
          },
          {
            path: ':id/pdf_reports_list',
            name: Names.R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS_PDF_REPORTS_LIST,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/analytics/pdf/PdfReportsPage.vue'),
          },
          {
            path: ':id/pptx_reports_list',
            name: Names.R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS_PPTX_REPORTS_LIST,
            component: () => import(/* webpackChunkName "[request]" */ '@/components/analytics/pdf/PdfReportsPage.vue'),
            props: {
              pageType: REPORT_TYPE.PPTX,
            }
          },
          {
            path: ':id/yandex_gpt',
            name: Names.R_YANDEX_GPT,
            component: () => import(/* webpackChunkName "[request]" */ '@/views/app/YandexGpt.vue'),
          },
        ],
      },
      {
        path: 'settings',
        name: Names.R_APP_SETTINGS,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/Settings.vue'),
        },
        meta: { middleware: [createAbilityMiddleware([ABILITIES.CAN_SEE_SETTINGS_LEFT_MENU])] },
      },
      {
        path: 'user_surveys',
        name: Names.R_APP_USER_SURVEYS,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/UserSurveys.vue'),
        },
        meta: { middleware: [createAbilityMiddleware([ABILITIES.CAN_SEE_USER_SURVEYS_LEFT_MENU])] },
      },
    ],
  },
  {
    path: '/flexible-edit',
    name: Names.R_FLEXIBLE_EDIT,
    redirect: { name: Names.R_ERROR_404 },
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/EditFlexibleAnalyticsLayout.vue'),
    children: [
      {
        path: ':id',
        name: Names.R_FLEXIBLE_EDIT_ID,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/views/app/EditFlexibleAnalytics.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          'aside-right': () => import(/* webpackChunkName "[request]" */ '@/components/flexibleAnalytics/edit/TabsList.vue'),
        },
        meta: { middleware: [createAbilityMiddleware([ABILITIES.CAN_EDIT_ANALYTICS_VIZUALIZATION])] },
      },
    ],
  },
  {
    path: '/constructor',
    name: Names.R_CONSTRUCTOR,
    redirect: { name: Names.R_ERROR_404 },
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/ConstructorStepsLayout.vue'),
    children: [
      {
        path: 'survey/:id',
        name: Names.R_CONSTRUCTOR_SURVEY_ID,
        components: {
          nav: () => import(/* webpackChunkName "[request]" */ '@/components/app/AppNav.vue'),
          'aside-left': () => import(/* webpackChunkName "[request]" */ '@/components/app/AppAsideLeft.vue'),
          default: () => import(/* webpackChunkName "[request]" */ '@/components/constructor/EditSurvey.vue'),
          'aside-right': () => import(/* webpackChunkName "[request]" */ '@/components/constructor/components/ConstructorAsideRight.vue'),
        },
        meta: {
          middleware: [
            createAbilityMiddleware([
              ABILITIES.CAN_CREATE_SURVEY,
              ABILITIES.CAN_CRUD_ALL_SURVEY_DRAFTS,
              ABILITIES.CAN_CRUD_ONLY_OWN_SURVEY_DRAFTS,
            ]),
          ],
        },
        children: [
          {
            path: 'page/:pageId',
            name: Names.R_CONSTRUCTOR_SURVEY_ID_PAGE_ID,
            component: () => import(
              /* webpackChunkName "[request]" */ '@/components/constructor/components/surveyContent/SurveyContentPage.vue'
            ),
          },
          {
            path: 'settings',
            name: Names.R_CONSTRUCTOR_SURVEY_ID_SETTINGS,
            component: () => import(
              /* webpackChunkName "[request]" */ '@/components/constructor/components/surveySettings/SurveySettings.vue'
            ),
          },
          {
            path: 'launch',
            name: Names.R_CONSTRUCTOR_SURVEY_ID_LAUNCH,
            component: () => import(
              /* webpackChunkName "[request]" */ '@/components/constructor/components/surveyLaunch/SurveyLaunch.vue'
            ),
          },

          {
            path: 'publish',
            name: Names.R_CONSTRUCTOR_SURVEY_ID_PUBLISH,
            component: () => import(
              /* webpackChunkName "[request]" */ '@/components/constructor/components/surveyLaunch/SurveyPublish.vue'
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/chief_registration',
    name: Names.R_CHIEF_REGISTRATION,
    component: () => import(/* webpackChunkName "[request]" */ '@/layouts/app/FormLayout.vue'),
    children: [
      {
        path: 'sign_up',
        name: Names.R_CHIEF_REGISTRATION_SIGN_UP,
        component: () => import(/* webpackChunkName "[request]" */ '@/views/app/Registration.vue'),
      },
    ],
  },
  {
    path: '/analytics_export',
    name: Names.R_ANALYTICS_EXPORT,
    component: () => import(/* webpackChunkName "[request]" */ '@/views/app/ExportPdfLayout.vue'),
  },
  {
    path: '/analytics_export_v2',
    name: Names.R_ANALYTICS_EXPORT_V2,
    component: () => import(/* webpackChunkName "[request]" */ '@/views/app/ExportPdfLayoutV2.vue'),
  },
  {
    path: '/gpt_export_pdf',
    name: Names.R_ANALYTICS_EXPORT_PDF_GPT,
    component: () => import(/* webpackChunkName "[request]" */ '@/views/app/ExportPdfGptLayout.vue'),
  },
  {
    path: '/gpt_export_pptx',
    name: Names.R_ANALYTICS_EXPORT_PPTX_GPT,
    component: () => import(/* webpackChunkName "[request]" */ '@/views/app/ExportPptxGptLayout.vue'),
  },
  {
    path: '/analytics_export_pptx',
    name: Names.R_ANALYTICS_EXPORT_PPTX,
    component: () => import(/* webpackChunkName "[request]" */ '@/views/app/ExportPptxLayout.vue'),
  },
  {
    path: '*',
    redirect: { name: Names.R_ERROR_404 },
  },
];

export default routes;
