export const R_APP = 'app';
export const R_APP_SURVEY = 'app.survey';

export const R_APP_SURVEY_ID_STATISTICS = 'app.survey.id.statistics';

export const R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS = 'app.survey.id.flexible.analytics';
export const R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS_PDF_REPORTS_LIST = 'app.survey.id.flexible.analytics.pdfReportsList';
export const R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS_PPTX_REPORTS_LIST = 'app.survey.id.flexible.analytics.pptxReportsList';
export const R_APP_SURVEY_ID_FLEXIBLE_ANALYTICS_ACTION_PLAN_TRASH = 'app.survey.id.flexible.analytics.actionPlanTrash';

export const R_APP_PRACTICES = 'app.practices';
export const R_APP_PRACTICE_ID = 'app.practice.practiceId';
export const R_APP_PRACTICES_CREATE = 'app.practices.create';
export const R_APP_PRACTICES_EDIT_ID = 'app.practices.edit.practiceId';

export const R_APP_CONSTRUCTION = 'app.construction';
export const R_APP_CONSTRUCTION_COMMON = 'app.construction.common';
export const R_APP_CONSTRUCTION_RESULTS_SURVEY_ID = 'app.construction.results.survey.id';
export const R_APP_CONSTRUCTION_DRAFTS = 'app.construction.drafts';
export const R_APP_CONSTRUCTION_TRASH = 'app.construction.trash';
export const R_APP_CONSTRUCTION_TEMPLATES = 'app.construction.templates';
export const R_APP_CONSTRUCTION_SHOP = 'app.construction.shop';
export const R_APP_CONSTRUCTION_BRANCHING_SURVEY_ID = 'app.construction.branching.survey.id';

export const R_CONSTRUCTOR = 'app.constructor';
export const R_CONSTRUCTOR_SURVEY_ID = 'app.constructor.survey.id';
export const R_CONSTRUCTOR_SURVEY_ID_PAGE_ID = 'app.constructor.survey.id.page.pageId';
export const R_CONSTRUCTOR_SURVEY_ID_SETTINGS = 'app.constructor.survey.id.settings';
export const R_CONSTRUCTOR_SURVEY_ID_LAUNCH = 'app.constructor.survey.id.launch';
export const R_CONSTRUCTOR_SURVEY_ID_PUBLISH = 'app.constructor.survey.id.publish';

export const R_FLEXIBLE_EDIT = 'app.flexible.edit';
export const R_FLEXIBLE_EDIT_ID = 'app.flexible.edit.id';

export const R_APP_SURVEYS = 'app.surveys';

export const R_APP_USER_SURVEYS = 'app.user.surveys';

export const R_APP_SETTINGS = 'app.settings';

export const R_CHIEF_REGISTRATION = 'chiefRegistration';
export const R_ANALYTICS_EXPORT = 'analyticsExport';
export const R_ANALYTICS_EXPORT_V2 = 'analyticsExportV2';
export const R_ANALYTICS_EXPORT_PDF_GPT = 'analyticsExportPdfGpt';
export const R_ANALYTICS_EXPORT_PPTX_GPT = 'analyticsExportPptxGpt';
export const R_ANALYTICS_EXPORT_PPTX = 'analyticsExportPptx';
export const R_YANDEX_GPT = 'yandexGPT';
export const R_CHIEF_REGISTRATION_SIGN_UP = 'chiefRegistration.signUp';
export const R_APP_DEMO_USER_REGISTRATION = 'demoUserRegistration';
export const R_ERROR_401 = 'errors.401';
export const R_ERROR_403 = 'errors.403';
export const R_ERROR_404 = 'errors.404';
export const R_ERROR_500 = 'errors.500';
